import { push } from 'connected-react-router';

import {
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  REDIRECT_URL_AFTER_LOGIN,
  ACTIVATE,
} from './types';
import ApiAuth from '../services/ApiAuth';
import { open } from '../actions/modals';

export const login = (data) => ({
  type: LOGIN.START,
  payload: data,
});

export const logout = () => {
  return (dispatch) => {
    ApiAuth.logout();
    dispatch({ type: LOGOUT });
  };
};

export const register = (data) => ({
  type: REGISTER.START,
  payload: data,
});

export const activate = (token) => ({
  type: ACTIVATE.START,
  payload: token,
});

export const resetPassword = (email) => ({
  type: RESET_PASSWORD.START,
  payload: email,
});

export const changePassword = (data) => {
  return {
    type: CHANGE_PASSWORD.START,
    payload: data,
  };
};

export const clearRedirectUrlAfterLogin = () => ({
  type: REDIRECT_URL_AFTER_LOGIN.CLEAR,
});

const setRedirectUrlAfterLogin = ({
  redirectUrlAfterLogin,
  redirectToExternalUrl,
}) => ({
  payload: {
    redirectUrlAfterLogin,
    redirectToExternalUrl,
  },
  type: REDIRECT_URL_AFTER_LOGIN.SET,
});

export const goToAfterLogin = (url) => (dispatch, getState) => {
  const state = getState();
  const isLogin = !!state.login.data.username;

  if (isLogin) {
    dispatch(push(url));
    return;
  }

  dispatch(
    setRedirectUrlAfterLogin({
      redirectUrlAfterLogin: url,
      redirectToExternalUrl: true,
    })
  );
  dispatch(open('LOGIN'));
};

export const goToRedirectUrl = () => (dispatch, getState) => {
  const state = getState();
  const url = state.login.redirectUrlAfterLogin;

  if (url) {
    dispatch(push(url));
    dispatch(clearRedirectUrlAfterLogin());
  }
};

export default {
  login,
  logout,
  register,
  activate,
  resetPassword,
  changePassword,
  clearRedirectUrlAfterLogin,
  setRedirectUrlAfterLogin,
  goToAfterLogin,
  goToRedirectUrl,
};
