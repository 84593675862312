import { RESET_PASSWORD } from '../actions/types';

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD.START:
      return {
        ...state,
        isLoading: true,
        data: null,
      };
    case RESET_PASSWORD.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
      };
    case RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: action.payload,
      };
    default:
      return state;
  }
};
