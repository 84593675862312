import React from 'react';
import propTypes from 'prop-types';
import { Route } from 'react-router-dom';

import TopBar from '../TopBar/TopBar';
import Footer from '../Footer/Footer';
import CrossDomainProvider from '../CrossDomain/CrossDomainProvider';
import CrossDomainReceiver from '../CrossDomain/CrossDomainReceiver';


class GlobalLayout extends React.Component {
  render() {
    return (
      <CrossDomainProvider>
        <TopBar />
        <div className="page-content">
          {this.props.children}
        </div>
        <Footer />
        <Route path="/cross-domain" component={CrossDomainReceiver} />
      </CrossDomainProvider>
    );
  }
}

GlobalLayout.propTypes = {
  children: propTypes.element,
};

export default GlobalLayout;
