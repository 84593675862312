import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import askLogo from '../../assets/img/logo-footer.svg';
import euLogo from '../../assets/img/EU_logo.svg';
import acoucouLogo from '../../assets/img/logo_acoucou_white.svg';

import * as modalActions from '../../actions/modals';
import modalNames from '../modals/names';

import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-additional">
          <p>
            <FormattedMessage id="footer.additional.text" />
          </p>
          <p>
            <FormattedMessage id="footer.additional.mail" />
          </p>
        </div>
        <div className="footer-top">
          <div className="container py-5 text-center text-md-left">
            <div className="row">
              <div
                className={`col-lg-3 col-md-3 offset-md-0 col-sm-6 offset-sm-3 col-8 offset-2 py-4`}
              >
                <img
                  src={askLogo}
                  alt="ACE"
                  className="img-fluid mx-auto logo"
                />
              </div>
              <div className="col-md py-4 footer-section">
                <div className="footer-section-element">
                  <FormattedMessage id="footer.products.title" />
                </div>
                <a
                  href="http://ace.acoucou.org"
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.products.ace" />
                </a>
                <a
                  href="http://arac-multibook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.products.arac" />
                </a>
                <a
                  href="https://aci.acoucou.org/"
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.products.aci" />
                </a>
                <a
                  href="http://scientificzone.acoucou.org"
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.products.scientific_zone" />
                </a>
                <div
                  className="footer-section-element"
                  onClick={() => this.props.openModal(modalNames.CONTACT)}
                >
                  <FormattedMessage id="footer.products.contact" />
                </div>
              </div>
              <div className="col-md py-4 footer-section">
                <div className="footer-section-element">
                  <FormattedMessage id="footer.about.title" />
                </div>
                <div
                  className="footer-section-element"
                  onClick={() =>
                    this.props.openModal(modalNames.WORK_IN_PROGRESS)
                  }
                >
                  <FormattedMessage id="footer.about.materials" />
                </div>
                <Link className="footer-section-element" to="/about">
                  <FormattedMessage id="footer.about.aci" />
                </Link>
              </div>
              <div className="col-md py-4 footer-section">
                <div className="footer-section-element">
                  <FormattedMessage id="footer.social.title" />
                </div>
                <a
                  href={'https://twitter.com/Acoucou_org'}
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.social.twitter" />
                </a>
                <a
                  href={'https://www.facebook.com/acoucou.org/'}
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.social.facebook" />
                </a>
                <a
                  href={'https://www.instagram.com/acoucouacoustics/'}
                  className="footer-section-element"
                >
                  <FormattedMessage id="footer.social.instagram" />
                </a>
              </div>
              <div className="col-md py-4 footer-section">
                <div className="footer-section-element">
                  <FormattedMessage id="footer.legal.title" />
                </div>
                <div className="footer-section-element">
                  <FormattedMessage id="footer.legal.use" />
                </div>
              </div>
            </div>
            <div className="text-right">
              <Link to="/">
                <img className="logo-acc" src={acoucouLogo} alt="" />
              </Link>
              <span className="footer-bottom-logo-text">
                <FormattedMessage id="footer.acoucou.text" />
              </span>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center align-self-center">
              <img className="p-4" src={euLogo} alt="" />
            </div>
            <div className="col-md-7 offset-md-1 text-center text-md-left">
              <p className="footer-bottom-text p-4">
                <FormattedMessage id="footer.bottom.text" />
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  openModal: propTypes.func,
};

export default connect(null, {
  openModal: modalActions.open,
})(Footer);
