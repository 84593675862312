import * as React from 'react';

import TeamPersonView from './TeamPersonView';
import LightBoxModal from './LightBox';
import Contact from './Contact';
import WorkInProgress from './WorkInProgress';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';

class AllModals extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TeamPersonView />
        <LightBoxModal />
        <Contact />
        <WorkInProgress/>
        <Login />
        <Register />
        <ResetPassword />
      </React.Fragment>
    );
  }
}

export default AllModals;
