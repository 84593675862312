import * as React from 'react';
import GlobalLayout from '../components/GlobalLayout/GlobalLayout';
import Team from '../components/Team/Team';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import Gallery from '../components/Gallery/Gallery';
import {gallery} from '../components/Gallery/config';
import {teamAbout} from '../components/Team/config';

import LogosSlider from '../components/LogosSlider/LogosSlider';

import { partnersLogos } from '../components/LogosSlider/config';

import './AboutPage.scss';

class AboutPage extends React.Component {

  renderSlider() {
    const settings = {
      arrows: true,
      autoplay: true,
      dots: true,
      draggable: false,
      fade: false,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      swipe: true,
      swipeToSlide: true,
      touchMove: true,
    };

    return (
      <div className="container mb-5">
        <div className="about-us-slider pb-5 px-3">
          <Slider {...settings}>
            {gallery.map((image, index) => {
              return (
                <div key={index}>
                  <img src={image} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }

  render() {
    return (
      <GlobalLayout>
        <div className="about-page py-5">
          <div className="col-12 mb-5 overflow-hidden py-5">
            <div className="quote pt-5">
              <span className="content">
                  <FormattedMessage id="page.about.hero.quote.content" />
              </span>

              <span className="author">
                  <span>-</span>
                  <FormattedMessage id="page.about.hero.quote.author" />
              </span>
            </div>
          </div>
          
          <div className="gallery">
            <Gallery />
          </div>

          <div className="gallery-slider">
            {this.renderSlider()}
          </div>
          <h2 className="section-heading text-center pb-5"><FormattedMessage id="about.section.title"/></h2>
          <div className="container">
            <div className="col-12 about-text-columns">
              <div className="text-column">
                <p><FormattedMessage id="about.section.text1"/></p>
                <p><FormattedMessage id="about.section.text2"/></p>
              </div>
              <div className="text-column">
                <p><FormattedMessage id="about.section.text3"/></p>
                <p><FormattedMessage id="about.section.text4"/></p>
                <p><FormattedMessage id="about.section.text5"/></p>
              </div>
            </div>
          </div>
          <div className="col-12 pt-5">
            <h4 className="section-heading">
              <FormattedMessage id="page.about.partners.title" />
            </h4>
          </div>
          <LogosSlider logos={partnersLogos} />

          <h2 className="section-heading text-center py-5"><FormattedMessage id="about.team.title"/></h2>
          <Team team={teamAbout}/>
        </div>
      </GlobalLayout>
    );
  }
}

export default AboutPage;
