import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {Modal} from 'reactstrap';
import propTypes from 'prop-types';

import ModalConnector from '../Generic/Modals/ModalConnector';

// import {sendMail, resetMailData} from '../../actions/mailActions';

import names from './names';

import './Contact.scss';

class Contact extends React.Component {
  state = {
    email: '',
    message: '',
    showErrorMessage: false,
    showSuccessMessage: false,
    showValidation: false,
  };

  validateEmail(email) {
    const reg = new RegExp([
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/.source,
      /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
    ].join(''));

    return email.length >= 2 && email.length <= 180 && reg.test(email);
  }

  validateMessage(message) {
    return message.length >= 6;
  }

  handleModalClose = (onClose) => {
    // this.props.resetMailData();
    onClose();
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();

    const { email, message } = this.state;
    if (!this.validateEmail(email) || !this.validateMessage(message)) {
      return this.setState({
        showValidation: true,
      });
    }

    const formData = new FormData();

    formData.append('email', email);
    formData.append('message', message);

    this.props.sendMail(formData);
  };

  render() {

    return (
      <ModalConnector name={names.CONTACT}>
        {
          ({isOpen, onClose}) => {
            return (
              <Modal
                size="md"
                isOpen={isOpen}
                toggle={() => this.handleModalClose(onClose)}
                contentClassName="modal-contact-content"
                className="modal-contact"
              >
                <div className="d-flex justify-content-end">
                  <span
                    onClick={() => this.handleModalClose(onClose)}
                    className="icon-close close-btn modal-contact-close"
                  />
                </div>
                <h2 className="modal-contact-title">
                  <FormattedMessage id="modal.contact.title" />
                </h2>
                <p className="modal-contact-subtitle">
                  <FormattedMessage id="modal.contact.subtitle" />
                  <br/>
                  <a
                    className="info-link link-primary link-bold"
                    href="mailto:asknow@acoucou.org">asknow@acoucou.org
                  </a>
                </p>
                <button
                  onClick={() => this.handleModalClose(onClose)}
                  className="btn btn-block btn-secondary modal-wip-confirm m-2" type="button">
                  <FormattedMessage id="modal.work.btn.thanks" />
                </button>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

Contact.propTypes = {
  mailSuccess: propTypes.bool,
  mailError: propTypes.bool,
  sendMail: propTypes.func,
  intl: propTypes.object,
  // resetMailData: propTypes.func,
};

export default injectIntl(Contact);
