
import * as React from 'react';

import './SocialBar.scss';

import fbIcon from '../../assets/img/socialIcons/fb-icon.svg';
import instagramIcon from '../../assets/img/socialIcons/instagram-icon.svg';
import twitterIcon from '../../assets/img/socialIcons/twitter-icon.svg';

class SocialBar extends React.Component {
  render() {
    return (
      <div className="social-bar d-flex flex-column">
        <a className="social-bar__icon" href="https://www.facebook.com/acoucou.org/">
            <img src={fbIcon} className="social-bar__icon" />
        </a>
        <a className="social-bar__icon" href="https://twitter.com/Acoucou_org">
            <img src={twitterIcon} className="social-bar__icon" />
        </a>
        <a className="social-bar__icon" href="https://www.instagram.com/acoucouacoustics/">
            <img src={instagramIcon} className="social-bar__icon" />
        </a>
      </div>
    );
  }
}

export default SocialBar;