import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { UI_API_URL } from 'appConfig';

import NewsItem from '../components/NewsItem/NewsItem';
import GlobalLayout from '../components/GlobalLayout/GlobalLayout';

import './ArticlesPage.scss';

class ArticlesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      news: [],
      perPage: 12,
      currentPage: 0,
    };
  }

  loadNews = () => {
    fetch(`${UI_API_URL}/news-items?_sort=date:desc`)
      .then((res) => res.json())
      .then((res) => {
        const data = res;
        const news = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          news,
        });
      });
  };

  componentDidMount() {
    this.loadNews();
  }

  handlePageClick = (news) => {
    window.scrollTo(0, 0);
    const selected = news.selected;
    const offset = Math.ceil(selected * this.state.perPage);

    this.setState({ offset: offset, currentPage: selected }, () => {
      this.loadNews();
    });
  };

  render() {
    return (
      <GlobalLayout>
        <>
          <div className="articles-header d-flex justify-content-center align-items-center text-white mb-3">
            <FormattedMessage id="articles.header" />
          </div>

          <div className="container">
            <div className="row">
              {this.state.news ? (
                <>
                  {this.state.news.map((newsItem, key) => (
                    <NewsItem
                      key={key}
                      title={newsItem.Title}
                      date={newsItem.Date}
                      excerpt={newsItem.Excerpt}
                      image={newsItem.CoverImage.url}
                      id={newsItem.id}
                    />
                  ))}
                </>
              ) : (
                <div className="w-100 d-flex justify-content-center align-content-center articles-loader">
                  <div className="loader"></div>
                </div>
              )}

              {this.state.newsData && (
                <ReactPaginate
                  previousLabel={
                    <span className="icon-pagination article-icon-prev"></span>
                  }
                  nextLabel={
                    <span className="icon-pagination article-icon-next"></span>
                  }
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination pagination-numbers'}
                  activeClassName={'active'}
                  activeLinkClassName={'pagination-active'}
                />
              )}
            </div>
          </div>
        </>
      </GlobalLayout>
    );
  }
}

export default ArticlesPage;
