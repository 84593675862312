import * as React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import propTypes from 'prop-types';

import modalActions from '../../actions/modals';
import modalNames from '../modals/names';

import { gallery} from '../Gallery/config';

class LightBoxSlider extends React.Component {
  slider;

  constructor(props) {
      super(props);

      this.state = {
          slide: this.props.data.slide,
      };
  }

  next = () => {
      this.slider.slickNext();
  };

  previous = () => {
      this.slider.slickPrev();
  };


  render() {
    const settings = {
        arrows: false,
        autoplay: false,
        className: 'lightbox-slick',
        dots: false,
        fade: true,
        infinite: true,
        initialSlide: this.state.slide,
        slidesToScroll: 1,
        slidesToShow: 1,
        swipe: false,
        touchMove: false,
    };

    return(
      this.state.slide < 0 ? null :
        <Slider  ref={(s) => { this.slider = s; }} {...settings}>
          {gallery.map((image, index) => {
            return (
              <div className="lightbox__content" key={index}>
                <img className="lightbox__image" src={image} />
                <div className="lightbox__bottom">
                  <div className="lightbox__controls">
                    <span
                      className="icon-back lightbox__control-icon"
                      onClick={this.previous}
                    />
                    <span
                      className="icon-forward lightbox__control-icon"
                      onClick={this.next}
                    />
                    <span
                      className="icon-x lightbox__control-icon lightbox__control-icon--close"
                      onClick={() => this.props.closeModal(modalNames.LIGHTBOX)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
    );
  }
}

LightBoxSlider.propTypes = {
  data: propTypes.object,
  closeModal: propTypes.func,
};

export default connect(
  null,
  ({
    closeModal: modalActions.close,
  }),
)(LightBoxSlider);
