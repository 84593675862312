import * as React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { UI_API_URL } from 'appConfig';

import './NewsItem.scss';

const NewsItem = (props) => {
  const { title, date, excerpt, image, id } = props;

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 pt-3 pb-3 px-lg-4">
      <div className="item-border">
        <Link to={`/news/${id}`} className="item-link">
          <div
            className="item-image mt-3"
            style={{
              backgroundImage: `url(${UI_API_URL}${image})`,
            }}
          ></div>
          <span className="item-date px-3">
            {dayjs(date).format('YYYY-MM-DD')}
          </span>
          <h4 className="item-title pt-3 px-3 font-weight-bold">{title}</h4>
          <p className="item-content pt-3 px-3">{excerpt}</p>
        </Link>
      </div>
    </div>
  );
};
export default NewsItem;

NewsItem.propTypes = {
  date: propTypes.string,
  title: propTypes.string,
  excerpt: propTypes.string,
  image: propTypes.string,
  id: propTypes.number,
};
