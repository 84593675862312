import teamMember1 from '../assets/img/team/andreas-herweg.jpg';
import teamMember2 from '../assets/img/team/andre-kruh-elendt.jpg';
import teamMember3 from '../assets/img/team/anne-heimes.jpg';
import teamMember4 from '../assets/img/team/antonin-novak.jpg';
import teamMember5 from '../assets/img/team/eckhard-kahle.jpg';
import teamMember6 from '../assets/img/team/emilie-carayol.jpg';

import {team} from '../components/Team/config';

export const teamPhotos = {
  teamMember1,
  teamMember2,
  teamMember3,
  teamMember4,
  teamMember5,
  teamMember6,
};

export const teamCourses = {
  1: [
    team.jeanPierre,
    team.samuelRaetz,
    team.michaelVorlander,
    team.anneHeimes
  ],
  2: [
    team.andreasHerwerg,
    team.andreKruh,
    team.alokeparnaRay,
    team.monikaRychtarikova,
    team.lukasAspok,
    team.anneHeimes
  ],
  3: [
    team.michaelVorlander,
    team.lukasAspok,
    team.anneHeimes,
    team.andreasHerwerg,
    team.andreKruh,
    team.thomasWulfrank,
    team.olivierDazel
  ],
  4: [
    team.anneHeimes,
    team.markoHorvat,
    team.manuelMelon,
    team.antoninNovak,
    team.antonioPetosic,
    team.michaelVorlander
  ],
  5: [
    team.markoHorvat,
    team.kristianJambrosic,
    team.antonioPetosic,
    team.monikaRychtarikova,
    team.yannickSluyts,
    team.thomasWulfrank,
    team.emilieCarayol,
    team.karolinaJaruszewska
  ]
};

export const summaryCourses = {
  1: {
    name: 'summary.course1.name',
    title: 'summary.course1.title',
    text1: 'summary.course1.text1',
    text2: 'summary.course1.text2'
  },
  2: {
    name: 'summary.course2.name',
    title: 'summary.course2.title',
    text1: 'summary.course2.text1',
    text2: 'summary.course2.text2'
  },
  3: {
    name: 'summary.course3.name',
    title: 'summary.course3.title',
    text1: 'summary.course3.text1',
    text2: 'summary.course3.text2'
  },
  4: {
    name: 'summary.course4.name',
    title: 'summary.course4.title',
    text1: 'summary.course4.text1',
    text2: 'summary.course4.text2'
  },
  5: {
    name: 'summary.course5.name',
    title: 'summary.course5.title',
    text1: 'summary.course5.text1',
    text2: 'summary.course5.text2'
  }
};