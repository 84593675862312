import { MAIL_SEND, MAIL_RESET_DATA } from '../actions/types';

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MAIL_SEND.START:
      return {
        ...state,
        isLoading: true,
      };
    case MAIL_SEND.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case MAIL_SEND.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    case MAIL_RESET_DATA:
      return {
        ...state,
      };
    default:
      return state;
  }
};
