import { createLogic } from 'redux-logic';
import { push } from 'connected-react-router';

import { LOGIN, REGISTER, RESET_PASSWORD,ACTIVATE, CHANGE_PASSWORD } from '../actions/types';
import ApiAuth from '../services/ApiAuth';
import { close } from '../actions/modals';
import { clearRedirectUrlAfterLogin } from '../actions/authActions';

export const login = createLogic({
  type: LOGIN.START,
  processOptions: {
    successType: LOGIN.SUCCESS,
    failType: LOGIN.ERROR,
  },

  process({action}) {
    return ApiAuth.login(action.payload);
  }
});

export const redirectAfterLoginSuccess = createLogic({
  type: LOGIN.SUCCESS,

  process({ getState }, dispatch, done) {
    const state = getState();
    const url = state.login.redirectUrlAfterLogin;
    const isExternalUrl = state.login.redirectToExternalUrl;

    if (url) {
      if (isExternalUrl) {
        window.location.href = url;
      } else {
        dispatch(push(url));
      }

      dispatch(clearRedirectUrlAfterLogin());
    }
    done();
  }
});

export const closeModalAfterSuccessfullyLogin = createLogic({
  type: LOGIN.SUCCESS,

  process(data, dispatch, done) {
    dispatch(close({ name: 'LOGIN' }));
    done();
  },
});

export const register = createLogic({
  type: REGISTER.START,
  processOptions: {
    successType: REGISTER.SUCCESS,
    failType: REGISTER.ERROR,
  },

  process({action}) {
    return ApiAuth.register(action.payload);
  }
});

export const resetPassword = createLogic({
  type: RESET_PASSWORD.START,
  processOptions: {
    successType: RESET_PASSWORD.SUCCESS,
    failType: RESET_PASSWORD.ERROR,
  },

  process({action}) {

    return ApiAuth.resetPassword(action.payload);
  }
});

export const changePassword = createLogic({
  type: CHANGE_PASSWORD.START,
  processOptions: {
    successType: CHANGE_PASSWORD.SUCCESS,
    failType: CHANGE_PASSWORD.ERROR,
  },

  process({action}) {
    return ApiAuth.changePassword(action.payload);
  }
});

export const activate = createLogic({
  type: ACTIVATE.START,
  processOptions: {
    successType: ACTIVATE.SUCCESS,
    failType: ACTIVATE.ERROR,
  },

  process({action}) {
    return ApiAuth.activate(action.payload);
  }
});

export default [
  login,
  redirectAfterLoginSuccess,
  closeModalAfterSuccessfullyLogin,
  activate,
  register,
  resetPassword,
  changePassword,
];
