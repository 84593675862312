import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalBody} from 'reactstrap';

import ModalConnector from '../Generic/Modals/ModalConnector';

import names from './names';

import './TeamPersonView.scss';

class TeamPersonView extends React.Component {
  render() {
    return (
      <ModalConnector name={names.TEAM_PERSON_VIEW}>
        {
          ({isOpen, data, onClose}) => {
            return (
              <Modal
                isOpen={isOpen}
                toggle={onClose}
                centered={true}
                contentClassName="modal-width"
                className="modal-person-wrapper"
              >
                <ModalBody>
                  <div className="modal-person">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close person"
                        onClick={onClose}
                      >
                        <span className="icon-close" />
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">
                        <img className="person-img" src={data.picture} alt="" />
                      </div>
                      <div className="col-lg-9 person-data">
                        <h2 className="person-name">{data.name}</h2>
                        <p className="person-info">
                            <FormattedMessage id={data.id} />
                        </p>
                        {data.footer ? data.footer : null}
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

export default TeamPersonView;
