import * as React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { activate } from '../actions/authActions';
import * as modalActions from '../actions/modals';
import modalNames from '../components/modals/names';
import GlobalLayout from '../components/GlobalLayout/GlobalLayout';
import { FormattedMessage } from 'react-intl';
import user from '../assets/img/user.svg';

class RegisterActivatePage extends React.Component {
  componentDidMount() {
    this.props.activateAction(this.props.match.params.token);
  }

  componentDidUpdate(){
    if(this.props.activate.isSuccess){
      setTimeout(()=>{
        this.props.openModal(modalNames.LOGIN);
      },2000);
    }
  }

  renderError() {
    return (
      <>
        <h3 className="text-center">
          <FormattedMessage id="register_activate.error.title" />
        </h3>
        <img className="mx-auto d-block" src={user} alt="" />

        <p className="text-center">
          <FormattedMessage id="register_activate.error.message" />
        </p>
      </>
    );
  }
  renderLoading() {
    return (
      <>
        <h3 className="text-center">
          <FormattedMessage id="register_activate.loading.title" />
        </h3>
        <img className="mx-auto d-block" src={user} alt="" />

        <p className="text-center">
          <FormattedMessage id="register_activate.loading.message" />
        </p>
      </>
    );
  }
  renderSuccess() {
    return (
      <>
        <h3 className="text-center">
          <FormattedMessage id="register_activate.success.title" />
        </h3>
        <img className="mx-auto d-block" src={user} alt="" />

        <p className="text-center">
          <FormattedMessage id="register_activate.success.message" />
        </p>
</>
    );
  }

  render() {
    const { activate } = this.props;
    return (
      <GlobalLayout>
         <div className="register-activate-page py-5">
        {activate.isError && this.renderError()}
        {activate.isLoading && this.renderLoading()}
        {activate.isSuccess && this.renderSuccess()}
        </div>
      </GlobalLayout>
    );
  }
}

RegisterActivatePage.propTypes = {
  token: propTypes.string,
  match: propTypes.object,
  activate: propTypes.object,
  activateAction: propTypes.func,
  openModal: propTypes.func
};
export default connect(
  (store) => ({ activate: store.activate }),
  (dispatch) => ({
    activateAction: (token) => dispatch(activate(token)),
    openModal: (name)=> dispatch(modalActions.open(name))
  }),

)(RegisterActivatePage);
