import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import SocialBar from '../components/SocialBar/SocialBar';
import GlobalLayout from '../components/GlobalLayout/GlobalLayout';
import LogosSlider from '../components/LogosSlider/LogosSlider';
import acoucouLogo from '../assets/img/acoucou_logo.svg';

import propTypes from 'prop-types';
import * as modalActions from '../actions/modals';
import modalNames from '../components/modals/names';

import { partnersLogos } from '../components/LogosSlider/config';

import { UI_LMS_URL } from 'appConfig';

import './Homepage.scss';
import NewsItemsList from '../components/NewsItem/NewsItems';
import { goToAfterLogin } from '../actions/authActions';

const courses = [
  {
    titleId: 'homepage.tile1.title',
    courseId: '41545ab2-1f99-40ea-8225-21d5e62ab7b0',
  },
  {
    titleId: 'homepage.tile2.title',
    courseId: '4eb31243-b6cb-423f-919d-15bba103f7ce',
  },  {
    titleId: 'homepage.tile3.title',
    courseId: '49817b85-0b30-4661-8a25-1e5176041865',
  },{
    titleId: 'homepage.tile4.title',
    courseId: 'e0db4477-1995-4380-9c35-2338dcf5e504',
  },{
    titleId: 'homepage.tile5.title',
    courseId: '53e2fab0-edf6-4634-a20e-ae0cdfe73cba',
  },
];

class HomePage extends React.Component {
  renderCourseButton = (courseId, isLogged) => {
    const courseUrl = `${UI_LMS_URL}/course/${courseId}`;
    if (isLogged) {
      return (
        <a
          href={courseUrl}
          className="btn btn-lg  btn-primary"
        >
            <FormattedMessage id="homepage.title.btn.course" />
        </a>
      );
    }

    return <div
      onClick={() => this.props.goToAfterLogin(courseUrl)}
      className="btn btn-lg  btn-primary"
    >
      <FormattedMessage id="homepage.tile.btn" />
    </div>;
  };

  renderCoursesTile = (course, isLogged) => {
    return <div className="tile">
      <div className="tile-icon">
        <span className="tile-icon-content icon-arrowR" />
      </div>
      <div className="tile-more">
        <FormattedMessage id="homepage.tile.more" />
      </div>
      <div className="tile-title">
        <FormattedMessage id={course.titleId} />
      </div>
      {this.renderCourseButton(course.courseId, isLogged)}
    </div>;
  };

  render() {
    const { loginData } = this.props;
    const isLogged = loginData && !!loginData.username;
    return (
      <GlobalLayout>
        <div className="home-page">
          <SocialBar />

          <div className="home-hero">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1 className="display display-4 text-center">
                    <FormattedMessage id="homepage.hero.text1" />
                    <br />
                    <span className="display--bold hero-text">
                      <FormattedMessage id="homepage.hero.text2" />
                    </span>
                  </h1>

                  <div className="row home-hero__buttons">
                    <div className="col-12 col-lg-4 m-auto btn-wrap">
                      <div
                        className="btn btn-lg btn-block btn-secondary-inverse btn-lg"
                        onClick={() =>
                          this.props.openModal(modalNames.REGISTER)
                        }
                      >
                        <FormattedMessage id="homepage.hero.button1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-s1">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="text-center text-white home-s1-title">
                    <FormattedMessage id="homepage.hero.courses" />
                  </div>
                  <div className="tile-wrapper">
                    {courses.map((course) => <React.Fragment key={course.courseId}>
                      {this.renderCoursesTile(course, isLogged)}
                    </React.Fragment>)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="text-center home-news-title">
              <FormattedMessage id="homepage.news.title" />
            </div>
            <NewsItemsList />

            <div className="row py-5">
              <div className="col-lg-4 offset-lg-4 col-8 offset-2">
                <div className="special-button-offset">
                  <Link
                    to="/news"
                    className="btn btn-lg btn-block btn-secondary"
                  >
                    <FormattedMessage id="homepage.news.btn.text" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="home-s2 aci py-5">
            <div className="container-fluid p-0">
              <h3 className="home-s2-title display text-center">
                <FormattedMessage id="homepage.s1.title" />
              </h3>
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <p className="home-s2-text text-center py-4">
                    <FormattedMessage
                      id="homepage.s1.p1"
                      values={{ b: (...chunks) => <b>{chunks}</b> }}
                    />
                  </p>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-lg-4 offset-lg-4 col-8 offset-2">
                  <div className="special-button-offset">
                    <Link
                      to="/about"
                      className="btn btn-lg btn-block btn-secondary-inverse btn-secondary-inverse--brand special-button"
                    >
                      <FormattedMessage id="homepage.s1.btn.text" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-s3">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="section-heading text-center">
                    <FormattedMessage id="homepage.s2.title" />
                  </h2>
                  <p className="home-s3-text">
                    <FormattedMessage
                      id="homepage.s2.text"
                      values={{ b: (...chunks) => <b>{chunks}</b> }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section-logo">
            <Link to="/">
              <img src={acoucouLogo} alt="" />
            </Link>
          </div>
          <div className="col-12 pt-5">
            <h4 className="section-heading">
              <FormattedMessage id="page.about.partners.title" />
            </h4>
          </div>
          <LogosSlider logos={partnersLogos} />
        </div>
      </GlobalLayout>
    );
  }
}

HomePage.propTypes = {
  loginData: propTypes.object,
  openModal: propTypes.func,
  goToAfterLogin: propTypes.func,
};

export default connect(
  (state) => ({
    loginData: state.login.data,
  }), {
  openModal: modalActions.open,
  goToAfterLogin: goToAfterLogin,
})(HomePage);
