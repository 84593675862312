import * as React from 'react';

import placeholder from '../../assets/img/team/user_photo.png';
import teamMember1 from '../../assets/img/team/andreas-herweg.jpg';
import teamMember2 from '../../assets/img/team/andre-kruh-elendt.jpg';
import teamMember3 from '../../assets/img/team/anne-heimes.jpg';
import teamMember4 from '../../assets/img/team/antonin-novak.jpg';
import teamMember5 from '../../assets/img/team/eckhard-kahle.jpg';
import teamMember6 from '../../assets/img/team/emilie-carayol.jpg';
import teamMember7 from '../../assets/img/team/filip-baranski.jpg';
import teamMember8 from '../../assets/img/team/jean-pierre-dalmont.jpg';
import teamMember9 from '../../assets/img/team/karolina-jaruszewska.jpg';
import teamMember10 from '../../assets/img/team/kristian-jambrosic.jpg';
import teamMember11 from '../../assets/img/team/lukas-aspok.jpg';
import teamMember12 from '../../assets/img/team/manuel-melon.jpg';
import teamMember13 from '../../assets/img/team/marko-horvat.jpg';
import teamMember14 from '../../assets/img/team/michael-vorlander.jpg';
import teamMember15 from '../../assets/img/team/monika-rychtarikova.jpg';
import teamMember16 from '../../assets/img/team/olivier-dazel.jpg';
import teamMember17 from '../../assets/img/team/samuel-raetz.jpg';
import teamMember18 from '../../assets/img/team/thomas-wulfrank.jpg';
import teamMember19 from '../../assets/img/team/yannick-sluyts.jpg';
import teamMember20 from '../../assets/img/team/alokeparna-ray.jpg';
import teamMember21 from '../../assets/img/team/antonio-petosic.jpg';
import teamMember22 from '../../assets/img/team/blazej-wojtyla.jpg';
import teamMember23 from '../../assets/img/team/maciej-swierczek.jpg';
import teamMember24 from '../../assets/img/team/seweryn-zeman.jpg';
import teamMember25 from '../../assets/img/team/marcin-luczak.jpg';

export const teamPhotos = {
  placeholder,
  teamMember1,
  teamMember2,
  teamMember3,
  teamMember4,
  teamMember5,
  teamMember6,
  teamMember7,
  teamMember8,
  teamMember9,
  teamMember10,
  teamMember11,
  teamMember12,
  teamMember13,
  teamMember14,
  teamMember15,
  teamMember16,
  teamMember17,
  teamMember18,
  teamMember19,
  teamMember20,
  teamMember21,
  teamMember22,
  teamMember23,
  teamMember24,
  teamMember25,
};

export const team = {
  alokeparnaRay: {
    footer: (
      <a
        className="person-footer"
        href="https://www.head-acoustics.com/eng/index.htm"
      >
        HEAD ACOUSTICS
      </a>
    ),
    id: 'modal.person.info.alokeparna.ray',
    name: 'Alokeparna Ray',
    picture: teamPhotos.teamMember20,
    position: 'Acoustics Engineer - Sound Perception & Assessment NVH',
  },
  andreasHerwerg: {
    footer: (
      <a
        className="person-footer"
        href="https://www.head-acoustics.com/eng/index.htm"
      >
        HEAD ACOUSTICS
      </a>
    ),
    id: 'modal.person.info.andreas.herwerg',
    name: 'Andreas Herweg',
    picture: teamPhotos.teamMember1,
    position: 'Manager of Sound Perception & Assessment NVH',
  },
  andreKruh: {
    footer: (
      <a
        className="person-footer"
        href="https://www.head-acoustics.com/eng/index.htm"
      >
        HEAD ACOUSTICS
      </a>
    ),
    id: 'modal.person.info.andre.kruh',
    name: 'André Kruh-Elendt',
    picture: teamPhotos.teamMember2,
    position: 'Research Engineer - Sound Perception & Assessment NVH',
  },
  anneHeimes: {
    footer: (
      <div>
        <a
          className="person-footer"
          href="http://www.akustik.rwth-aachen.de/cms/~dwma/Technische-Akustik/"
        >
          ITA RWTH AACHEN
        </a>
        <a
          className="person-footer"
          href="https://www.akustik.rwth-aachen.de/cms/Technische-Akustik/Das-Institut/Team/TA-Team/~fzdpt/Anne-Heimes/"
        >
          Anne Heimes @ ITA
        </a>
        <a
          className="person-footer"
          href="https://www.researchgate.net/profile/Anne_Heimes"
        >
          Anne Heimes @ ResearchGate
        </a>
      </div>
    ),
    id: 'modal.person.info.anne.heims',
    name: 'Anne Heimes',
    picture: teamPhotos.teamMember3,
    position: 'Research Assistant at RWTH Aachen University',
  },
  antoninNovak: {
    footer: (
      <a className="person-footer" href="https://ant-novak.com/">
        https://ant-novak.com/
      </a>
    ),
    id: 'modal.person.info.antonin.novak',
    name: 'Antonin Novak',
    picture: teamPhotos.teamMember4,
    position: 'Research Engineer at Le Mans University',
  },
  antonioPetosic: {
    footer: (
      <a
        className="person-footer"
        href="https://www.fer.unizg.hr/en/antonio.petosic"
      >
        Research
      </a>
    ),
    id: 'modal.person.info.antonio.petosic',
    name: 'Antonio Petošić',
    picture: teamPhotos.teamMember21,
    position: 'Assistant Professor at the University of Zagreb',
  },
  eckardKahle: {
    footer: (
      <a
        className="person-footer"
        href="http://www.kahleacoustics.com/en/cv/kahle.html"
      >
        Kahle Acoustics
      </a>
    ),
    id: 'modal.person.info.eckhard.kahle',
    name: 'Eckhard Kahle',
    picture: teamPhotos.teamMember5,
    position: 'CEO of Kahle Acoustics',
  },
  emilieCarayol: {
    footer: (
      <a
        className="person-footer"
        href="http://www.kahleacoustics.com/en/cv/carayol.html"
      >
        Kahle Acoustics
      </a>
    ),
    id: 'modal.person.info.emilie.carayol',
    name: 'Émilie Carayol',
    picture: teamPhotos.teamMember6,
    position: 'Acoustician at Kahle Acoustics',
  },
  filipBaranski: {
    footer: (
      <a className="person-footer" href="https://kfb-acoustics.com">
        KFB Acoustics
      </a>
    ),
    id: 'modal.person.info.filip.baranski',
    name: 'Filip Baranski',
    picture: teamPhotos.teamMember7,
    position: 'CEO of KFB Acoustics',
  },
  jeanPierre: {
    id: 'modal.person.info.jean.pierre',
    name: 'Jean-Pierre Dalmont',
    picture: teamPhotos.teamMember8,
    position: 'Professor at Le Mans University',
  },
  karolinaJaruszewska: {
    footer: (
      <a className="person-footer" href="https://kfb-acoustics.com">
        KFB Acoustics
      </a>
    ),
    id: 'modal.person.info.karolina.jaruszewska',
    name: 'Karolina Jaruszewska',
    picture: teamPhotos.teamMember9,
    position: 'Project Manager at KFB Acoustics',
  },
  kristianJambrosic: {
    footer: (
      <a
        className="person-footer"
        href="https://www.fer.unizg.hr/en/kristian.jambrosic"
      >
        Research
      </a>
    ),
    id: 'modal.person.info.kristian.jambrosic',
    name: 'Kristian Jambrošić',
    picture: teamPhotos.teamMember10,
    position: 'Professor at the University of Zagreb',
  },
  lukasAspok: {
    footer: (
      <div>
        <a
          className="person-footer"
          href="http://www.akustik.rwth-aachen.de/cms/~dwma/Technische-Akustik/"
        >
          ITA RWTH AACHEN
        </a>
        <a
          className="person-footer"
          // eslint-disable-next-line max-len
          href="https://www.akustik.rwth-aachen.de/cms/Technische-Akustik/Das-Institut/Team/TA-Team/~gmlf/Lukas-Aspoeck/"
        >
          Lukas Aspöck @ ITA
        </a>
        <a
          className="person-footer"
          href="https://www.researchgate.net/profile/Lukas_Aspoeck"
        >
          Lukas Aspöck @ ResearchGate
        </a>
      </div>
    ),
    id: 'modal.person.info.lukas.aspock',
    name: 'Lukas Aspöck',
    picture: teamPhotos.teamMember11,
    position: 'Research Assistant at RWTH Aachen University',
  },
  manuelMelon: {
    footer: (
      <a
        className="person-footer"
        href="http://electroacoustique.univ-lemans.fr/module_en.php?trad=en"
      >
        IMDEA
      </a>
    ),
    id: 'modal.person.info.manuel.melon',
    name: 'Manuel Melon',
    picture: teamPhotos.teamMember12,
    position: 'Professor at Le Mans University',
  },
  markoHorvat: {
    footer: (
      <a
        className="person-footer"
        href="https://www.fer.unizg.hr/en/marko.horvat "
      >
        Research
      </a>
    ),
    id: 'modal.person.info.marko.horvat',
    name: 'Marko Horvat',
    picture: teamPhotos.teamMember13,
    position: 'Assistant Professor at the University of Zagreb',
  },
  michaelVorlander: {
    footer: (
      <div>
        <a
          className="person-footer"
          href="http://www.akustik.rwth-aachen.de/cms/~dwma/Technische-Akustik/"
        >
          ITA RWTH AACHEN
        </a>
        <a
          className="person-footer"
          href="http://www.akustik.rwth-aachen.de/cms/Technische-Akustik/Das-Institut/Team/TA-Team/~dzus/Vorlaender"
        >
          Research
        </a>
      </div>
    ),
    id: 'modal.person.info.michael.vorlander',
    name: 'Michael Vorländer',
    picture: teamPhotos.teamMember14,
    position: 'Professor at RWTH Aachen University',
  },
  monikaRychtarikova: {
    footer: (
      <div>
        <a
          className="person-footer"
          href="https://www.kuleuven.be/wieiswie/en/person/00046505"
        >
          KU Leuven
        </a>
        <a
          className="person-footer"
          href="http://www.tedxbratislava.sk/en/video/monika-rychtarikova-big-bang-virtual-acoustics/"
        >
          TEDx Bratislava
        </a>
      </div>
    ),
    id: 'modal.person.info.monika.rychtarikova',
    name: 'Monika Rychtáriková',
    picture: teamPhotos.teamMember15,
    position: 'Professor at KU Leuven',
  },
  olivierDazel: {
    footer: (
      <a className="person-footer" href="http://perso.univ-lemans.fr/~odazel/">
        http://perso.univ-lemans.fr/~odazel/
      </a>
    ),
    id: 'modal.person.info.olivier.dazel',
    name: 'Olivier Dazel',
    picture: teamPhotos.teamMember16,
    position: 'Professor at Le Mans University',
  },
  samuelRaetz: {
    footer: (
      <a
        className="person-footer"
        href="http://perso.univ-lemans.fr/~sraetz/webpage/"
      >
        http://perso.univ-lemans.fr/~sraetz/webpage/
      </a>
    ),
    id: 'modal.person.info.samuel.raetz',
    name: 'Samuel Raetz',
    picture: teamPhotos.teamMember17,
    position: 'Assistant professor at Le Mans University',
  },
  thomasWulfrank: {
    footer: (
      <a
        className="person-footer"
        href="http://www.kahleacoustics.com/en/cv/wulfrank.html"
      >
        Kahle Acoustics
      </a>
    ),
    id: 'modal.person.info.thomas.wulfrank',
    name: 'Thomas Wulfrank',
    picture: teamPhotos.teamMember18,
    position: 'Acoustician at Kahle Acoustics',
  },
  yannickSluyts: {
    id: 'modal.person.info.yannick.sluyts',
    name: 'Yannick Sluyts',
    picture: teamPhotos.teamMember19,
    position: 'PhD Student at KU Leuven',
  },
  blazejWojtyla: {
    footer: (
      <a className="person-footer" href="https://jazzy.pro">
        Jazzy Innovations
      </a>
    ),
    id: 'modal.person.info.blazej.wojtyla',
    name: 'Błażej Wojtyła',
    picture: teamPhotos.teamMember22,
    position: 'CEO of Jazzy Innovations',
  },
  maciejSwierczek: {
    footer: (
      <a className="person-footer" href="https://jazzy.pro">
        Jazzy Innovations
      </a>
    ),
    id: 'modal.person.info.maciej.swierczek',
    name: 'Maciej Świerczek',
    picture: teamPhotos.teamMember23,
    position: 'Graphic Designer at Jazzy Innovations, Lead UI Designer',
  },
  // sewerynZeman: {
  //   footer: (
  //     <a className="person-footer" href="https://jazzy.pro">
  //       Jazzy Innovations
  //     </a>
  //   ),
  //   id: 'modal.person.info.seweryn.zeman',
  //   name: 'Seweryn Zeman',
  //   picture: teamPhotos.teamMember24,
  //   position: 'CTO of Jazzy Innovations',
  // },
  marcinLuczak: {
    footer: (
      <a className="person-footer" href="https://kfb-acoustics.com">
        KFB Acoustics
      </a>
    ),
    id: 'modal.person.info.marcin.luczak',
    name: 'Marcin Łuczak',
    picture: teamPhotos.teamMember25,
    position: 'Content Manager at Jazzy Innovations',
  },
};

// keep alphabetical order
export const teamAbout = [
  team.alokeparnaRay,
  team.andreasHerwerg,
  team.andreKruh,
  team.anneHeimes,
  team.antoninNovak,
  team.antonioPetosic,
  team.blazejWojtyla,
  team.eckardKahle,
  team.emilieCarayol,
  team.filipBaranski,
  team.jeanPierre,
  team.karolinaJaruszewska,
  team.kristianJambrosic,
  team.lukasAspok,
  team.maciejSwierczek,
  team.manuelMelon,
  team.marcinLuczak,
  team.markoHorvat,
  team.michaelVorlander,
  team.monikaRychtarikova,
  team.olivierDazel,
  team.samuelRaetz,
  //team.sewerynZeman,
  team.thomasWulfrank,
  team.yannickSluyts,
];
