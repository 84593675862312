import React from 'react';
import PropTypes from 'prop-types';

import CrossDomainMessage from '../../services/CrossDomainMessage';

class CrossDomainProvider extends React.Component {
    static propTypes = {
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
    };

    componentDidMount() {
        CrossDomainMessage.createIframes();
    }

    render() {
        return this.props.children;
    }
}

export default CrossDomainProvider;
