import {MODAL} from './types';

export const open = (name, data = null) => ({
  type: MODAL.OPEN,
  payload: {
    name,
    data,
  }
});

export const close = (name) => ({
  type: MODAL.CLOSE,
  payload: {
    name,
  }
});

export const closeActualOpened = () => ({
  type: MODAL.CLOSE_ACTUAL_OPENED,
});

export default {
  open,
  close,
  closeActualOpened,
};
