import React from 'react';
import { UI_API_URL } from 'appConfig';

import NewsItem from './NewsItem';

import './NewsItem.scss';

class NewsItemsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      news: [],
    };
  }

  componentDidMount() {
    fetch(`${UI_API_URL}/news-items?_sort=date:desc`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            news: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, news } = this.state;

    const someNews = news.slice(0, 6);

    if (error) {
      return <p className="text-center item-error">Error: {error.message}</p>;
    } else if (!isLoaded) {
      return (
        <div className="d-flex justify-content-center w-100">
          <div className="loader"></div>
        </div>
      );
    } else {
      return (
        <div className="row">
          {someNews?.map((newsItem, key) => (
            <NewsItem
              key={key}
              title={newsItem.Title}
              date={newsItem.Date}
              excerpt={newsItem.Excerpt}
              image={newsItem.CoverImage.url}
              id={newsItem.id}
            />
          ))}
        </div>
      );
    }
  }
}

export default NewsItemsList;
