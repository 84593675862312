import * as React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import { UI_API_URL } from 'appConfig';

import GlobalLayout from '../components/GlobalLayout/GlobalLayout';

import './ArticlePage.scss';

class ArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    fetch(`${UI_API_URL}/news-items/${params.id}`)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          news: result,
        });
      });
  }

  render() {
    const { news } = this.state;

    return (
      <GlobalLayout>
        <div className="article-page">
          <div
            // eslint-disable-next-line
            className="article-header text-center d-flex align-items-center justify-content-around flex-column pb-4"
          >
            {news?.CoverImage?.url ? (
              <div
                className="article-bgimg"
                style={{
                  backgroundImage: `url(${UI_API_URL}${news?.CoverImage?.url})`,
                }}
              ></div>
            ) : (
              <div
                className="article-bgimg"
                style={{
                  backgroundImage: `url(${UI_API_URL}/Bg_grey_stripes.jpg)`,
                }}
              ></div>
            )}

            <div className="d-flex flex-column align-items-center">
              <div className="article-subtitle text-white text-center text-uppercase">
                <FormattedMessage id="article.subtitle" />
              </div>

              <div className="article-date text-white text-uppercase">
                <span> {dayjs(news?.Date).format('YYYY-MM-DD')}</span>
              </div>

              <p className="text-white article-title col-10">{news?.Title}</p>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-4 col-xl-3 mt-3 mb-4">
              <Link to="/news">
                <div className="btn btn-lg btn-block btn-secondary-inverse btn-lg text-uppercase article-button">
                  <FormattedMessage id="article.more" />
                </div>
              </Link>
            </div>
          </div>

          <div className="row article-text d-flex justify-content-center align-items-center">
            <div className="article-content col-lg-8 col-md-10 col-sm-12">
              <div className="article-p">
                <ReactMarkdown source={news?.Excerpt}></ReactMarkdown>
                <ReactMarkdown source={news?.Content}></ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}

export default ArticlePage;

ArticlePage.propTypes = {
  id: propTypes.string,
  match: propTypes.object,
};
