import gallery1 from '../../assets/img/about/about_02.jpg';
import gallery7 from '../../assets/img/about/about_06.jpg';
import gallery3 from '../../assets/img/about/about_04.jpg';
import gallery4 from '../../assets/img/about/about_03.jpg';
import gallery5 from '../../assets/img/about/about_01.jpg';
import gallery6 from '../../assets/img/about/about_05.jpg';
import gallery2 from '../../assets/img/about/about_07.jpg';

export const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
];
