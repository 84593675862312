import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import GlobalLayout from '../components/GlobalLayout/GlobalLayout';
import Team from '../components/Team/Team';

import { summaryCourses, teamCourses } from './summary-config';

import './SummaryCourse.scss';

class SummaryCourse extends React.Component {
  renderContent(text1, text2) {
    if (!text2) {
      return (
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="summary-about-text">
              <FormattedMessage id={text1} />
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="summary-about-text-columns">
        <p>
          <FormattedMessage id={text1} />
        </p>
        <p>
          <FormattedMessage id={text2} />
        </p>
      </div>
    );
  }

  render() {
    const courseData = summaryCourses[this.props.match.params.id];
    const teamData = teamCourses[this.props.match.params.id];

    if (!courseData) {
      return null;
    }

    return (
      <GlobalLayout>
        <div className="summary">
          <div className="summary-hero text-center">
            <div className="container">
              <span className="label-primary">
                <FormattedMessage id="summary.hero.label" />
              </span>
              <h1 className="display display-4 py-5">
                <FormattedMessage id={courseData.name} />
              </h1>

              <div className="row py-5">
                <div className="special-button-offset">
                  <div className="col-lg-4 offset-lg-4 col-8 offset-2">
                    <Link
                      to="/about"
                      className="btn btn-lg btn-block btn-secondary-inverse btn-secondary-inverse--brand special-button"
                    >
                      <FormattedMessage id="homepage.s1.btn.text" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-about text-center py-5">
            <div className="container">
              <h2 className="section-heading text-center pb-5">
                <FormattedMessage id={courseData.title} />
              </h2>

              {this.renderContent(courseData.text1, courseData.text2)}
            </div>
          </div>
          <div className="col-12 py-5">
            <h4 className="team-heading text-center">
              <FormattedMessage id="team.title" />
            </h4>
          </div>
          {teamData && <Team team={teamData} />}
        </div>
      </GlobalLayout>
    );
  }
}

SummaryCourse.propTypes = {
  match: PropTypes.object,
};

export default SummaryCourse;
