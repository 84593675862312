import * as React from 'react';
import {Modal} from 'reactstrap';

import ModalConnector from '../Generic/Modals/ModalConnector';
import names from '../modals/names';

import LightBoxSlider from '../LightBoxSlider/LightBoxSlider';

class LightBoxModal extends React.Component {
  render() {
    return (
      <ModalConnector name={names.LIGHTBOX}>
      {
        ({isOpen, data, onClose}) => {
          return (
            <div className="lightbox-modal">
              <Modal
                isOpen={isOpen}
                toggle={onClose}
                centered={true}
                contentClassName="modal-width lightbox-modal"
                className="modal-person-wrapper"
              >
                <LightBoxSlider data={data} />
              </Modal>
            </div>
          );
        }
      }
      </ModalConnector>
    );
  }
}

export default LightBoxModal;
