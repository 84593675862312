import { MODAL } from '../actions/types';

const initialState = {
  isOpen: false,
  name: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL.OPEN:
      return {
        isOpen: true,
        name: action.payload.name,
        data: action.payload.data,
      };
    case MODAL.CLOSE:
      return initialState;
    case MODAL.CLOSE_ACTUAL_OPENED:
      return initialState;
    default:
      return state;
  }
};
