import { hot } from 'react-hot-loader';
import React from 'react';
import TagManager from 'react-gtm-module';
import propTypes from 'prop-types';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import {Switch, Route} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import About from '../pages/AboutPage';
import SummaryCourse from '../pages/SummaryCourse';
import HomePage from '../pages/HomePage';
import ScrollToTop from './utils/ScrollToTop.jsx';

import AllModals from './modals/AllModals';

import store, { history } from '../store';

import messageEn from '../assets/i18n/en.json';
import ArticlePage from '../pages/ArticlePage';
import ArticlesPage from '../pages/ArticlesPage';
import { UI_GTM_TAG } from 'appConfig';
import RegisterActivatePage from '../pages/RegisterActivatePage';
import ChangePasswordPage from '../pages/ChangePasswordPage';

if (UI_GTM_TAG) {
    const tagManagerArgs = {
        gtmId: UI_GTM_TAG,
    };
    TagManager.initialize(tagManagerArgs);
}


class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <IntlProvider locale={'en'} messages={messageEn}>
          <ConnectedRouter history={history}>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/about" component={About} />
                <Route path="/summary/:id" component={SummaryCourse} />
                <Route path="/news/:id" component={ArticlePage} />
                <Route path="/register/activate/:token" component={RegisterActivatePage} />
                <Route path="/reset-password/:token" component={ChangePasswordPage} />
                <Route path="/news" component={ArticlesPage} />
              </Switch>
              <AllModals />
            </ScrollToTop>
          </ConnectedRouter>
        </IntlProvider>
      </Provider>

    );
  }
}

App.propTypes = {
  history: propTypes.object,
};

export default hot(module)(App);
