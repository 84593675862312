import { createLogic } from 'redux-logic';

import {MAIL_SEND} from '../actions/types';

export const send = createLogic({
  type: MAIL_SEND.START,
  processOptions: {
    successType: MAIL_SEND.SUCCESS,
    failType: MAIL_SEND.ERROR,
  },

  process({apiMail, action}) {
    return apiMail.post('', action.payload.body);
  }
});

export default {
  send,
};
