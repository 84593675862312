import * as React from 'react';
import PropTypes from 'prop-types';
import * as classnames from 'classnames';
import Slider from 'react-slick';

import './LogoSlider.scss';

const settings = {
  autoplay: true,
  arrows: true,
  dots: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
          slidesToShow: 1,
      },
    },
  ],
  slidesToScroll: 1,
  slidesToShow: 5,
  speed: 500,
  swipeToSlide: false,
  touchMove: false,
  variableWidth: false,
};

class LogosSlider extends React.Component {
  render() {
    return (
      <div className="logos-slider">
        <div className="container">
          <div className="row">
            <div className="col-12 py-5">
              <div className="px-3 px-md-0">
                <Slider {...settings}>
                    {
                      this.props.logos.map((logo) => {
                        return (
                          <div key={logo.id} className="px-3">
                            <a href={logo.link} target="_blank" className="logo-wrapper" rel="noopener noreferrer">
                              <img
                                src={logo.bw}
                                className={classnames('img-fluid mx-auto logo-image logo-bw', {
                                    'w-50': logo.id === 'pwr',
                                })}
                              />
                              <img
                                src={logo.color}
                                className={classnames('img-fluid mx-auto logo-image logo-color', {
                                    'w-50': logo.id === 'pwr',
                                })}
                              />
                            </a>
                          </div>
                        );
                      })
                    }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LogosSlider.propTypes = {
  logos: PropTypes.array,
};


export default LogosSlider;
