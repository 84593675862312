import React from 'react';

import ApiAuth from '../../services/ApiAuth';
import CrossDomainMessage from '../../services/CrossDomainMessage';

class CrossDomainReceiver extends React.Component {
    componentDidMount() {
        window.addEventListener('message', this.onMessage());
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onMessage());
    }

    onMessage = () => {
        return CrossDomainMessage.onReceiveMessageFromAny((data) => {
          switch (data.name) {
            case 'onLogin':
              ApiAuth.saveAuthSelf(data.value);
              break;
            case 'onLogout':
              ApiAuth.logoutSelf();
              break;
          }
        });
    };

    render() {
        return null;
    }
}

export default CrossDomainReceiver;
