import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import modal from './modal';
import mail from './mail';
import login from './login';
import register from './register';
import activate from './activate';
import resetPassword from './resetPassword';
import changePassword from './changePassword';

export default (history) => combineReducers({
  router: connectRouter(history),
  activate,
  modal,
  mail,
  login,
  register,
  resetPassword,
  changePassword
});
