import jazzy from '../../assets/img/partners/jazzy-grey.svg';
import jazzyColor from '../../assets/img/partners/jazzy-color.svg';
import kfb from '../../assets/img/partners/kfb-grey.svg';
import kfbColor from '../../assets/img/partners/kfb-color.svg';
import chalmers from '../../assets/img/partners/chalmers-grey.svg';
import chalmersColor from '../../assets/img/partners/chalmers.svg';
import dolby from '../../assets/img/partners/dolby-grey.svg';
import dolbyColor from '../../assets/img/partners/dolby-color.svg';
import fer from '../../assets/img/partners/fer.svg';
import ferColor from '../../assets/img/partners/fer-color.svg';
import head from '../../assets/img/partners/head-grey.svg';
import headColor from '../../assets/img/partners/head-color.svg';
import kahle from '../../assets/img/partners/kahle-grey.svg';
import kahleColor from '../../assets/img/partners/kahle-color.svg';
import lemans from '../../assets/img/partners/lemans.svg';
import lemansColor from '../../assets/img/partners/lemans-color.svg';
import leuven from '../../assets/img/partners/leuven-grey.svg';
import leuvenColor from '../../assets/img/partners/leuven-color.svg';
import rwth from '../../assets/img/partners/rwth.svg';
import rwthColor from '../../assets/img/partners/rwth-color.svg';
import upv from '../../assets/img/partners/upv-grey.svg';
import upvColor from '../../assets/img/partners/upv-color.svg';

export const partnersLogos = [
  {
      bw: jazzy,
      color: jazzyColor,
      id: 'jazzy',
      link: 'http://jazzy.pro',
  },
  {
      bw: kfb,
      color: kfbColor,
      id: 'kfb',
      link: 'http://kfb-acoustics.com',
  },
  {
    bw: fer,
    color: ferColor,
    id: 'fer',
    link: 'https://www.fer.unizg.hr/en'
  },
  {
    bw: head,
    color: headColor,
    id: 'head',
    link: 'https://www.head-acoustics.com/eng/index.htm'
  },
  {
    bw: kahle,
    color: kahleColor,
    id: 'kahle',
    link: 'http://www.kahle.be/'
  },
  {
    bw: lemans,
    color: lemansColor,
    id: 'lemans',
    link: 'http://www.univ-lemans.fr/en/index.html'
  },
  {
    bw: leuven,
    color: leuvenColor,
    id: 'leuven',
    link: 'https://www.kuleuven.be/kuleuven/'
  },
  {
    bw: rwth,
    color: rwthColor,
    id: 'rwth',
    link: 'https://www.akustik.rwth-aachen.de/'
  }
];

export const associatedLogos = [
  {
      bw: chalmers,
      color: chalmersColor,
      id: 'chalmers',
      link: 'http://www.ta.chalmers.se/',
  },
  {
    bw: dolby,
    color: dolbyColor,
    id: 'dolby',
    link: 'https://www.dolby.com',
  },
  {
    bw: upv,
    color: upvColor,
    id: 'upv',
    link: 'https://www.upv.es/titulaciones/MUIA/indexi.html'
  }
];
