import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import './Team.scss';

class Team extends React.Component {
  togglePersonModal(person) {
    this.props.openModal(modalNames.TEAM_PERSON_VIEW, person);
  }

  render() {
  return (
    <div className="team">
      <div className="container">
        <div className="row py-5">
          {this.props.team.map((member, id) => {
            return (
              <div
                key={id}
                className="col-12 col-md-6 col-lg-4 py-4"
                onClick={() => this.togglePersonModal(member)}
              >
                <div className="d-flex flex-md-row flex-column team-member">
                  <div className="avatar-wrapper pr-0 pr-md-2 mx-auto mx-md-0">
                    <div className="avatar-img-wrapper">
                      <img
                        className="img-fluid rounded-circle avatar"
                        src={member.picture}
                        alt={member.name}
                      />
                    </div>
                    
                    <span className="icon-arrowR avatar-arrow" />
                  </div>
                  <div className={
                    `data-wrapper
                    d-flex flex-column justify-content-center
                    pl-0 pl-md-2 pt-4 pt-md-0 mx-auto mx-md-0
                    text-center text-md-left`
                  }>
                    <b className="about__team-member">{member.name}</b>
                    <div className="position">{member.position}</div>
                    {
                      member.extraPosition &&
                      <div className="position position--lighten">{member.extraPosition}</div>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
  }
}

Team.propTypes = {
  openModal: PropTypes.func,
  team: PropTypes.array
};

export default connect(
  null,
  ({
    openModal: modalActions.open,
  })
)(Team);
