import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from '../reducers';
import logicMiddleware from './logicMiddleware';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

const isDevTools = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined;
const middleware = applyMiddleware(routerMiddleware(history), logicMiddleware, thunk);

const enhancer = isDevTools
  ? compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
  : middleware;

export default createStore(createRootReducer(history), enhancer);
