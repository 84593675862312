import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import {
    resetPassword,
} from '../../actions/authActions';

import ModalConnector from '../Generic/Modals/ModalConnector';
import names from './names';

import './Contact.scss';

class ResetPassword extends React.Component {
  state = {
    email: '',
    showValidation: false,
  };

  validateEmail(email) {
    const reg = new RegExp([
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/.source,
      /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
    ].join(''));

    return email.length >= 2 && email.length <= 180 && reg.test(email);
  }

  handleModalClose = (onClose) => {
    onClose();
  };

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      showValidation: false,
    });
  };

  submitResetPassword = (e) => {
    e.preventDefault();

    if (!this.validateEmail(this.state.email.trim())) {
      return this.setState({
        showValidation: true,
      });
    }

    const data = {
      email: this.state.email.trim(),
    };

    this.props.resetPassword(data);
  };

  render() {
    const { intl } = this.props;
    const { email, showValidation } = this.state;

    return (
      <ModalConnector name={names.RESET_PASSWORD}>
        {
          ({isOpen, onClose}) => {
            return (
              <Modal
                size="md"
                isOpen={isOpen}
                toggle={() => this.handleModalClose(onClose)}
                contentClassName="modal-contact-content"
                className="modal-contact"
              >
                <div className="d-flex justify-content-end">
                  <span
                    onClick={() => this.handleModalClose(onClose)}
                    className="icon-close close-btn modal-contact-close"
                  />
                </div>
                <h2 className="modal-contact-title">
                  <FormattedMessage id="modal.reset.title" />
                </h2>
                <p className="modal-contact-subtitle">
                  <FormattedMessage id="modal.reset.subtitle" />
                </p>
                <form onSubmit={this.submitResetPassword}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder={intl.formatMessage({id: 'modal.reset.form.email'})}
                      value={email}
                      onChange={this.handleEmailChange}
                    />
                    {
                      showValidation &&
                      !this.validateEmail(this.state.email) &&
                      <div className="modal-error-message">
                          <FormattedMessage id="modal.register.errors.email" />
                      </div>
                    }
                    {
                      this.props.error &&
                      <div className="modal-error-message">
                        <FormattedMessage id="modal.reset.error" />
                      </div>
                    }
                    {
                      this.props.success &&
                      <div className="modal-success-message">
                        <FormattedMessage id="modal.reset.success" />
                      </div>
                    }
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <button className="btn btn-block btn-primary-inverse modal-contact-confirm">
                      <FormattedMessage id="modal.reset.form.submit" />
                    </button>
                  </div>
                </form>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

ResetPassword.propTypes = {
  intl: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default connect(
  (state) => ({
    error: state.resetPassword.isError,
    success: state.resetPassword.isSuccess,
  }),
  ({
    resetPassword,
  }),
)(injectIntl(ResetPassword));
