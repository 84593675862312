import * as React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePassword } from '../actions/authActions';
import GlobalLayout from '../components/GlobalLayout/GlobalLayout';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as modalActions from '../actions/modals';
import modalNames from '../components/modals/names';

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      repeatPassword: '',
      showValidation: false,
    };
  }

  componentDidUpdate(){
    if(this.props.changePassword.isSuccess){
      setTimeout(()=>{
        this.props.openModal(modalNames.LOGIN);
      },2000);
    }
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleRepeatPasswordChange = (e) => {
    this.setState({
      repeatPassword: e.target.value,
    });
  };
  submitPasswordChange = (e) => {
    e.preventDefault();

    const password = this.state.password.trim();
    const repeatPassword = this.state.repeatPassword.trim();

    if (!this.validatePassword(password, repeatPassword)) {
      return this.setState({
        showValidation: true,
      });
    }

    const data = {
      confirmationToken: this.props.match.params.token,
      password,
    };
    this.props.changePasswordAction(data);
  };

  validatePassword(password, repeatPassword) {
    return (
      password.length >= 8 &&
      password.length <= 4096 &&
      password === repeatPassword
    );
  }

  render() {
    const { intl } = this.props;
    const { password, repeatPassword, showValidation } = this.state;
    return (
      <GlobalLayout>
        <div className="change-password-page py-5 container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h3 className="text-center">
                <FormattedMessage id="change_password.title" />
              </h3>
              <p className="text-center">
                <FormattedMessage id="change_password.subtitle" />
              </p>
              <form>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder={intl.formatMessage({
                      id: 'change_password.form.password',
                    })}
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder={intl.formatMessage({
                      id: 'change_password.form.repassword',
                    })}
                    value={repeatPassword}
                    onChange={this.handleRepeatPasswordChange}
                  />
                  {showValidation &&
                    !this.validatePassword(password, repeatPassword) && (
                      <div className="modal-error-message">
                        <FormattedMessage id="modal.register.errors.password" />
                      </div>
                    )}
                  {this.props.changePassword.isError && (
                      <div className="modal-error-message">
                        <FormattedMessage id="change_password.errors.token" />
                      </div>
                    )}
                  {this.props.changePassword.isSuccess && (
                    <div className="modal-success-message">
                      <FormattedMessage id="change_password.success.message" />
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-primary-inverse btn-block"
                  onClick={this.submitPasswordChange}
                >
                  <FormattedMessage id="change_password.form.submit" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
ChangePasswordPage.propTypes = {
  token: propTypes.string,
  match: propTypes.object,
  changePassword: propTypes.object,
  changePasswordAction: propTypes.func,
  openModal: propTypes.func,
  intl: propTypes.func,

};
export default connect(
  (store) => ({ changePassword: store.changePassword }),
  (dispatch) => ({
    changePasswordAction: (token) => dispatch(changePassword(token)),
    openModal: (name)=> dispatch(modalActions.open(name))

  })
)(injectIntl(ChangePasswordPage));
