import { EventEmitter } from 'events';
import { each, findKey } from 'lodash';

class CrossDomainMessage extends EventEmitter {
    domains = {};

    constructor() {
        super();
    }

    init(domains) {
      each(domains, (domainConfig, name) => {
        this.domains[name] = {
            ...domainConfig,
        };
      });

      this.emit('init');
    }

    createIframes() {
      each(this.domains, (domainConfig, name) => {
        const iframe = document.createElement('iframe');
        const url = domainConfig.iframeUrl
          ? domainConfig.domainUrl + domainConfig.iframeUrl
          : domainConfig.domainUrl;

        iframe.setAttribute('src', url);
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.setAttribute('style', 'display: none');

        document.body.appendChild(iframe);

        this.domains[name] = {
            ...domainConfig,
            contentWindow: iframe.contentWindow,
        };
      });

      this.emit('ready');
    }

    sendMessage(domainName, messageName, messageValue) {
      const domain = this.domains[domainName];

      domain.contentWindow.postMessage({
        name: messageName,
        value: messageValue,
      }, domain.domainUrl);
    }

    sendMessageToAll(messageName, messageValue) {
      each(this.domains, (domainData) => {
        domainData.contentWindow.postMessage({
          name: messageName,
          value: messageValue,
        }, domainData.domainUrl);
      });
    }

    onReceiveMessage(domainName, action) {
      const domain = this.domains[domainName];

      return (e) => {
        if (e.origin !== domain.domainUrl) {
            return;
        }

        action(e.data);
      };
    }

    getDomainKeyByDomainUrl(domainUrl) {
      return findKey(this.domains, (domain) => domain.domainUrl === domainUrl);
    }

    onReceiveMessageFromAny(action) {
      return (e) => {
        if (!this.getDomainKeyByDomainUrl(e.origin)) {
            return;
        }

        action(e.data);
      };
    }
}

export default new CrossDomainMessage();
